interface ColorProfile {
    backgroundColor: string;
    color: string;
    userMessageBackgroundColor: string;
    userMessageColor: string;
    userMessageInProgressColor: string;
    tutorMessageBackgroundColor: string;
    tutorMessageColor: string;
}

const colorPalatte: { [key: string]: ColorProfile } = {
    default: {
        backgroundColor: '#fff',
        color: '#000',
        userMessageBackgroundColor: '#dcf8c6',
        userMessageColor: '#000',
        userMessageInProgressColor: '#a7d8ff',
        tutorMessageBackgroundColor: '#f1f0f0',
        tutorMessageColor: '#000',
    },
    'dark-mode': {
        backgroundColor: '#000',
        color: '#fff',
        userMessageBackgroundColor: '#4F46E5',
        userMessageColor: '#fff',
        userMessageInProgressColor: '#9590EF',
        tutorMessageBackgroundColor: '#1F2937',
        tutorMessageColor: '#CBD5E1',
    }
};

export function getColorProfile(profile: string): ColorProfile {
    return colorPalatte[profile] || colorPalatte.default;
}

