import {
  AudioConfig,
  CancellationReason,
  ResultReason,
  SpeechConfig,
  SpeechRecognizer,
} from 'microsoft-cognitiveservices-speech-sdk';
import { AzureTokenDetails } from './types';

export class AzureSpeechRecognition {
  recognizer: SpeechRecognizer | undefined;
  audioConfig: AudioConfig | undefined;
  private gainNode: GainNode | undefined;
  private audioContext: AudioContext | undefined;
  private echoCancellation: GainNode | undefined;

  isRunning(): boolean {
    return this.recognizer !== undefined;
  }

  async start(
    tokenDetails: AzureTokenDetails,
    onSpeechRecognized: (transcript: string, isFinal: boolean) => void,
    onSpeechRecognitionEnded: () => void,
    avatarAudioElement: HTMLAudioElement
  ) {
    const speechConfig = SpeechConfig.fromAuthorizationToken(tokenDetails.token, tokenDetails.region);
    speechConfig.speechRecognitionLanguage = 'en-US';

    // Set up Web Audio API
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    this.audioContext = new AudioContext();
    const source = this.audioContext.createMediaStreamSource(stream);
    
    // Create and connect the echo cancellation
    this.echoCancellation = this.createEchoCancellation(avatarAudioElement);
    source.connect(this.echoCancellation);

    // Create and connect the gain node
    this.gainNode = this.audioContext.createGain();
    this.echoCancellation.connect(this.gainNode);

    const destination = this.audioContext.createMediaStreamDestination();
    this.gainNode.connect(destination);

    // Create a custom audio config using the processed stream
    this.audioConfig = AudioConfig.fromStreamInput(destination.stream);

    this.recognizer = new SpeechRecognizer(speechConfig, this.audioConfig);

    this.recognizer.recognizing = (_, event) => {
      if (event.result.reason === ResultReason.RecognizingSpeech) {
        onSpeechRecognized(event.result.text, false);
      }
    };

    this.recognizer.recognized = (_, event) => {
      if (event.result.reason === ResultReason.RecognizedSpeech) {
        onSpeechRecognized(event.result.text, true);
      }
    };

    this.recognizer.canceled = (_, event) => {
      if (event.reason === CancellationReason.Error) {
        console.error(`CANCELED: ErrorCode=${event.errorCode}`);
        console.error(`CANCELED: ErrorDetails=${event.errorDetails}`);
        console.error('CANCELED: Did you set the speech resource key and region values?');
      }
      this.recognizer?.stopContinuousRecognitionAsync();
      onSpeechRecognitionEnded();
    };

    this.recognizer.sessionStopped = () => {
      this.recognizer?.stopContinuousRecognitionAsync();
      onSpeechRecognitionEnded();
    };

    await this.recognizer.startContinuousRecognitionAsync();
  }

  private createEchoCancellation(avatarAudioElement: HTMLAudioElement): GainNode {
    const echoCancellation = this.audioContext!.createGain();
    
    // Create a media element source for the avatar's audio
    const avatarSource = this.audioContext!.createMediaElementSource(avatarAudioElement);
    
    // Create an inverter for the avatar's audio
    const inverter = this.audioContext!.createGain();
    inverter.gain.value = -1;
    
    // Connect the avatar's audio to the inverter
    avatarSource.connect(inverter);
    
    // Connect the inverter to the echo cancellation node
    inverter.connect(echoCancellation);
    
    // Also connect the avatar's audio directly to the audio context destination
    // so we can still hear it
    avatarSource.connect(this.audioContext!.destination);
    
    return echoCancellation;
  }

  setSpeechRecognitionSensitivity(sensitivity: number) {
    if (this.gainNode) {
      // Adjust the microphone sensitivity
      // The valid range is 0.0 to 1.0, where 0.0 is muted and 1.0 is full volume
      console.log('Setting sensitivity to', sensitivity);
      this.gainNode.gain.setValueAtTime(sensitivity, this.audioContext!.currentTime);
    }
  }

  async stop() {
    if (this.recognizer) {
      try {
        await new Promise<void>((resolve, reject) => {
          this.recognizer!.stopContinuousRecognitionAsync(
            () => {
              this.recognizer!.close();
              this.recognizer = undefined;
              resolve();
            },
            (error) => {
              console.error("Error stopping continuous recognition:", error);
              reject(error);
            }
          );
        });
      } catch (error) {
        console.error("Error in stop method:", error);
      }
    }
  }
}
